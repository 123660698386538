var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-data-table',{staticClass:"elevation-1 ",attrs:{"headers":_vm.itemHeader,"items":_vm.items,"loading":_vm.loading,"items-per-page":5},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{staticClass:"mb-7",attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v("Banners Images")]),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('v-spacer'),(_vm.openDialog)?_c('ItemForm',{attrs:{"formTitle":_vm.formTitle,"dialog":_vm.openDialog,"record":_vm.record,"collection":_vm.collection,"items":_vm.items},on:{"closeDialog":function($event){_vm.openDialog = false},"formSubmitted":_vm.formSubmitted}}):_vm._e(),_c('v-btn',{staticClass:"mb-2",attrs:{"color":"primary","dark":""},on:{"click":function () {
              _vm.record = null;
              _vm.formTitle = 'Add New Item';
              _vm.openDialog = true;
            }}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-plus")])],1),_c('v-dialog',{attrs:{"max-width":"500px"},model:{value:(_vm.dialogDelete),callback:function ($$v) {_vm.dialogDelete=$$v},expression:"dialogDelete"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline"},[_vm._v("Are you sure you want to delete this item?")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.closeDelete}},[_vm._v("Cancel")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":function($event){return _vm.deleteItemConfirm()}}},[_vm._v("OK")]),_c('v-spacer')],1)],1)],1)],1)]},proxy:true},{key:"item.iconUrlar",fn:function(ref){
            var item = ref.item;
return [_c('v-img',{attrs:{"width":"40px","height":"40px","src":item.iconUrlar}})]}},{key:"item.iconUrlen",fn:function(ref){
            var item = ref.item;
return [_c('v-img',{attrs:{"width":"40px","height":"40px","src":item.iconUrlen}})]}},{key:"item.actions",fn:function(ref){
            var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"color":"primary","small":""},on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v(" mdi-pencil ")]),_c('v-icon',{staticClass:"mr-2",attrs:{"color":"error","small":""},on:{"click":function($event){return _vm.deleteItem(item)}}},[_vm._v(" mdi-delete ")])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }