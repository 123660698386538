<template>
  <v-dialog v-model="dialog" max-width="900px" persistent>
    <v-card>
      <v-card-title>
        <span color="primary" class="headline">{{ formTitle }}</span>
      </v-card-title>

      <v-card-text>
        <v-form ref="form" v-model="valid">
          <v-row>
            <v-col cols="12" md="6">
              <v-text-field
                v-model="formData.title"
                :rules="textRules"
                label="title"
                required
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="6">
              <v-text-field
                v-model="formData.sequence"
                :rules="textRules"
                label="sequence"
                required
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-autocomplete
                v-model="formData.type"
                :items="types"
                dense
                chips
                small-chips
                label="types"
                item-text="title"
                item-value="id"
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" md="6">
              <v-autocomplete
                :loading="loadingBanner"
                v-model="formData.banner_id"
                :items="banners"
                dense
                chips
                small-chips
                label="banners"
                item-text="title"
                item-value="id"
              ></v-autocomplete>
            </v-col>

            <v-col cols="12" md="6" v-if="formData.type == 'CT'">
              <v-autocomplete
                :loading="loadingCategory"
                v-model="formData.category_id"
                :items="categories"
                dense
                chips
                small-chips
                label="categories"
                item-text="arabic_title"
                item-value="id"
              ></v-autocomplete>
            </v-col>

            <v-col cols="12" md="6" v-if="formData.type == 'T'">
              <v-autocomplete
                :loading="loadingTag"
                v-model="formData.tag_id"
                :items="tags"
                dense
                chips
                small-chips
                label="tags"
                item-text="english_title"
                item-value="id"
              ></v-autocomplete>
            </v-col>

            <v-col cols="12" md="6" v-if="formData.type == 'B'">
              <v-autocomplete
                :loading="loadingBrand"
                v-model="formData.brand_id"
                :items="brands"
                dense
                chips
                small-chips
                label="brands"
                item-text="english_title"
                item-value="id"
              ></v-autocomplete>
            </v-col>

            <v-col cols="12" md="6" v-if="formData.type == 'CB'">
              <v-autocomplete
                :loading="loadingCelebrity"
                v-model="formData.celebrity_id"
                :items="celebrities"
                dense
                chips
                small-chips
                label="celebrities"
                item-text="first_name"
                item-value="id"
              ></v-autocomplete>
            </v-col>

            <v-col cols="12" md="6" v-if="formData.type == 'P'">
              <v-autocomplete
                :loading="loadingProduct"
                v-model="formData.product_id"
                :items="products"
                dense
                chips
                small-chips
                label="products"
                item-text="arabic_title"
                item-value="id"
              ></v-autocomplete>
            </v-col>

            <v-col cols="12" md="6">
              <v-file-input
                v-model="image"
                chips
                label="Select Image"
              ></v-file-input>
            </v-col>

            <v-col cols="12" md="6" v-if="file">
              <div class="image-area" style="margin-bottom: 10px;">
                <img
                  :src="file.data.full_url"
                  v-if="file"
                  width="250px"
                  height="250px"
                />
                <!-- @click="openLink(img.data.full_url)" -->

                <a
                  class="remove-image"
                  @click="removeimg(file.id)"
                  href="#"
                  style="display: inline;"
                  >&#215;</a
                >
              </div>
            </v-col>

            <v-col cols="12" md="6">
              <v-file-input
                v-model="image1"
                chips
                label="Select Image"
              ></v-file-input>
            </v-col>

            <v-col cols="12" md="6" v-if="file1">
              <div class="image-area" style="margin-bottom: 10px;">
                <img
                  :src="file1.data.full_url"
                  v-if="file1"
                  width="250px"
                  height="250px"
                />
                <!-- @click="openLink(img.data.full_url)" -->

                <a
                  class="remove-image"
                  @click="removeimg(file1.id)"
                  href="#"
                  style="display: inline;"
                  >&#215;</a
                >
              </div>
            </v-col>
            <!-- website -->
            <!-- start -->
            <v-col cols="12" md="6">
              <v-file-input
                v-model="wsimage"
                chips
                label="Select Image"
              ></v-file-input>
            </v-col>

            <v-col cols="12" md="6" v-if="wsfile">
              <div class="image-area" style="margin-bottom: 10px;">
                <img
                  :src="wsfile.data.full_url"
                  v-if="wsfile"
                  width="250px"
                  height="250px"
                />
                <!-- @click="openLink(img.data.full_url)" -->

                <a
                  class="remove-image"
                  @click="removeimg(wsfile.id)"
                  href="#"
                  style="display: inline;"
                  >&#215;</a
                >
              </div>
            </v-col>

            <v-col cols="12" md="6">
              <v-file-input
                v-model="wsimage1"
                chips
                label="Select Image"
              ></v-file-input>
            </v-col>

            <v-col cols="12" md="6" v-if="wsfile1">
              <div class="image-area" style="margin-bottom: 10px;">
                <img
                  :src="wsfile1.data.full_url"
                  v-if="wsfile1"
                  width="250px"
                  height="250px"
                />
                <!-- @click="openLink(img.data.full_url)" -->

                <a
                  class="remove-image"
                  @click="removeimg(wsfile1.id)"
                  href="#"
                  style="display: inline;"
                  >&#215;</a
                >
              </div>
            </v-col>
            <!-- end -->
          </v-row>
        </v-form>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="error darken-1" text @click="close">
          Cancel
        </v-btn>
        <v-btn color="blue darken-1" text @click="submit">
          Submit
        </v-btn>
      </v-card-actions>
    </v-card>

    <!-- snackbar -->
    <v-snackbar v-model="snackbar" absolute top :multi-line="true">
      {{ error }}

      <template v-slot:action="{ attrs }">
        <v-btn color="red" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </v-dialog>
</template>

<script>
import ApiService from "@/services/api.service";

export default {
  props: ["formTitle", "record", "dialog", "items", "collection"],
  data() {
    return {
      loadingProduct: false,
      loadingCategory: false,
      loadingTag: false,
      loadingBrand: false,
      loadingBanner: false,
      loadingCelebrity: false,
      categories: [],
      tags: [],
      celebrities: [],
      brands: [],
      products: [],
      banners: [],
      snackbar: false,
      valid: false,
      submitted: false,
      loading: false,
      error: "",
      loadingCategories: false,
      types: [
        { title: "Category", id: "CT" },
        { title: "Tag", id: "T" },
        { title: "Product", id: "P" },
        { title: "Brand", id: "B" },
        { title: "Celebrity", id: "CB" },
      ],
      formData: {
        id: null,
        title: null,
        category_id: null,
        tag_id: null,
        celebrity_id: null,
        brand_id: null,
        product_id: null,
        type: null,
        ar_image_id: null,
        en_image_id: null,
        ar_wsimage_id: null,
        en_wsimage_id: null,
        banner_id: null,
      },
      image: null,
      file: null,
      image1: null,
      file1: null,
      wsimage: null,
      wsfile: null,
      wsimage1: null,
      wsfile1: null,
      textRules: [(v) => !!v || "field is required"],

      // =================== for updating users ===================
      initialPayload: {},
    };
  },
  methods: {
    async removeimg(id) {
      const { data } = await ApiService.delete(`files/${id}`);
      this.formData["image"] = null;
      this.formData["wsimage"] = null;
      this.submit();
    },
    async submit() {
      // console.log(this.background_color);
      try {
        if (this.$refs.form.validate()) {
          this.submitted = true;

          if (this.image) {
            // send file
            let formData1 = new FormData();
            formData1.append("data", this.image);
            const file = await ApiService.postFile("files", formData1, 0, null);
            // send post req
            this.formData["ar_image_id"] = file.data.data.id;
          }
          if (this.image1) {
            // send file
            let formData2 = new FormData();
            formData2.append("data", this.image1);
            const file = await ApiService.postFile("files", formData2, 0, null);
            // send post req
            this.formData["en_image_id"] = file.data.data.id;
          }
          if (this.wsimage) {
            // send file
            let formData3 = new FormData();
            formData3.append("data", this.wsimage);
            const file = await ApiService.postFile("files", formData3, 0, null);
            // send post req
            this.formData["ar_wsimage_id"] = file.data.data.id;
          }
          if (this.wsimage1) {
            // send file
            let formData4 = new FormData();
            formData4.append("data", this.wsimage1);
            const file = await ApiService.postFile("files", formData4, 0, null);
            // send post req
            this.formData["en_wsimage_id"] = file.data.data.id;
          }
          if (this.record) {
            await ApiService.update(
              `items/${this.collection}`,
              this.formData.id,
              this.formData
            );
          } else {
            console.log(this.formData);
            await ApiService.post(`items/${this.collection}`, this.formData);
          }
          this.submitted = false;
          const msg = this.record
            ? "record updated successfully"
            : "record inserted successfully";
          this.$emit("formSubmitted", msg);
        } else {
          this.snackbar = true;
          this.error = "please fill all required fields";
        }
      } catch (error) {
        if (error.message !== "") {
          this.error = error;
        } else {
          this.error = "sorry, something went wrong";
        }
        this.snackbar = true;
        console.log(error);
        this.submitted = false;
      }
    },
    close() {
      this.$emit("closeDialog");
    },
  },

  async mounted() {
    const categories = await ApiService.get(
      "items/categories?fields=id,english_title,arabic_title"
    );
    this.categories = categories.data.data;

    const tags = await ApiService.get("items/tags?fields=id,english_title");
    this.tags = tags.data.data;

    const brands = await ApiService.get("items/brands?fields=id,english_title");
    this.brands = brands.data.data;

    const banners = await ApiService.get(
      "items/banners?fields=id,title,category_id.english_title,position,celebrity_id"
    );
    this.banners = banners.data.data;

    const products = await ApiService.get(
      "items/products?fields=id,english_title,arabic_title&filter[parent_id][null]&limit=-1"
    );
    this.products = products.data.data;

    const celebrities = await ApiService.get(
      "users?fields=id,first_name,last_name,sequence,avatar,email&filter[role]=3"
    );
    this.celebrities = celebrities.data.data;
    console.log(";;;;11", this.record);

    if (this.record) {
      Object.keys(this.formData).forEach((element) => {
        if (this.record[element]) {
          if (typeof this.record[element] == "object") {
            this.formData[element] = this.record[element].id;
          } else {
            this.formData[element] = this.record[element];
          }
        }
      });
      console.log(";;;;", this.formData);

      if (this.formData.ar_image_id) {
        // await this.getFile(this.formData.image);
        const { data } = await ApiService.getFile(
          "files",
          this.formData.ar_image_id
        );
        console.log(";;;;", data.data);
        this.file = data.data;
      }

      if (this.formData.en_image_id) {
        // await this.getFile(this.formData.image);
        const data1 = await ApiService.getFile(
          "files",
          this.formData.en_image_id
        );
        this.file1 = data1.data.date;
      }

      if (this.formData.ar_wsimage_id) {
        // await this.getFile(this.formData.image);
        const data2 = await ApiService.getFile(
          "files",
          this.formData.ar_wsimage_id
        );
        this.wsfile = data2.data.date;
      }

      if (this.formData.en_wsimage_id) {
        console.log(this.formData.en_wsimage_id);

        // await this.getFile(this.formData.image);
        const data3 = await ApiService.getFile(
          "files",
          this.formData.en_wsimage_id
        );
        console.log(data3.data.data);

        this.wsfile1 = data3.data.date;
        console.log(this.wsfile1);
      }
    }
  },
};
</script>
<style lang="css">
@import "~quill/dist/quill.core.css";
@import "~quill/dist/quill.snow.css";
@import "~quill/dist/quill.bubble.css";
.image-area {
  position: relative;
  width: 50%;
  background: #333;
}
.image-area img {
  max-width: 100%;
  height: auto;
}
.remove-image {
  display: none;
  position: absolute;
  top: -10px;
  right: -10px;
  border-radius: 10em;
  padding: 2px 6px 3px;
  text-decoration: none;
  font: 700 21px/20px sans-serif;
  background: #555;
  border: 3px solid #fff;
  color: #fff;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.5), inset 0 2px 4px rgba(0, 0, 0, 0.3);
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.5);
  -webkit-transition: background 0.5s;
  transition: background 0.5s;
}
.remove-image:hover {
  background: #e54e4e;
  padding: 3px 7px 5px;
  top: -11px;
  right: -11px;
}
.remove-image:active {
  background: #e54e4e;
  top: -10px;
  right: -11px;
}
</style>
