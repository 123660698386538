<template>
  <v-container fluid>
    <V-row>
      <v-col md="12">
        <SettingItem
          @loadData="loadData"
          collection="banners_images"
          :items="banners"
          :itemHeader="bannerItemHeader"
        />
      </v-col>
    </V-row>
  </v-container>
</template>

<script>
import SettingItem from "./SettingItem/index";
import ApiService from "@/services/api.service";

export default {
  components: {
    SettingItem,
  },
  data() {
    return {
      banners: [],
      bannerItemHeader: [
        { text: "Banner", value: "banner_id.title" },
        { text: "sequence", value: "sequence" },
        { text: "Title", value: "title" },
        { text: "arabic image", value: "iconUrlar" },
        { text: "english image", value: "iconUrlen" },
        { text: "Actions", value: "actions", sortable: false },
      ],
    };
  },
  computed: {},
  methods: {
    async loadData() {
      try {
        this.loading = true;
        this.fetchData();
        this.loading = false;
      } catch (error) {
        console.log(error);
      }
    },
    async fetchData() {
      try {
        const data = await ApiService.get(
          "items/banners_images?fields=id,title,type,banner_id.id,category_id,banner_id.title,product_id,celebrity_id,brand_id,ar_image_id,en_image_id,ar_wsimage_id,en_wsimage_id,sequence"
        );
        let banners = data.data.data;
        for (let banner of banners) {
          if (banner.ar_image_id) {
            const { data } = await ApiService.getFile(
              "files",
              banner.ar_image_id.id
            );
            // banner.iconUrlar = data.data.data.thumbnails[6].url;
            banner.iconUrlar = data.data.data.full_url;
          }
          if (banner.en_image_id) {
            const { data } = await ApiService.getFile(
              "files",
              banner.en_image_id.id
            );
            // banner.iconUrlen = data.data.data.thumbnails[6].url;
            banner.iconUrlen = data.data.data.full_url;
          }
        }
        this.banners = banners;
      } catch (error) {
        console.log(error);
      }
    },
  },
  mounted() {
    this.fetchData(undefined);
  },
};
</script>

<style></style>
